// List of all possible theme options and their defaults
let theme_options: {
  [key: string]: {
    selector: string;
    property: string;
    color: string;
  };
} = {
  progressBarBackground: {
    selector: ".vjs-progress-holder",
    property: "background",
    color: "rgba(244, 149, 0, 0.5)",
  },
  progressBarPlayed: {
    selector: ".vjs-progress-holder .vjs-play-progress",
    property: "backgroundColor",
    color: "#f49500",
  },
  progressBarLoaded: {
    selector: ".vjs-progress-holder .vjs-load-progress div",
    property: "background",
    color: "rgba(244, 149, 0, 0.5)",
  },
  progressBarPosition: {
    selector: ".vjs-play-progress.vjs-slider-bar, .vjs-volume-bar.vjs-slider",
    property: "color",
    color: "#ffffff",
  },

  volumeBarBackground: {
    selector: ".vjs-volume-control",
    property: "background",
    color: "rgba(75, 85, 92, 0.7)",
  },
  volumeBarLevel: {
    selector:
      ".vjs-volume-control .vjs-volume-level, .vjs-volume-control .vjs-slider-bar",
    property: "background",
    color: "#f49500",
  },

  overlayText: {
    selector: ".title-overlay-container",
    property: "color",
    color: "#f49500",
  },

  controlBarBackground: {
    selector: ".vjs-control-bar, .vjs-volume-vertical, .vjs-menu-content",
    property: "background",
    color: "rgba(75, 85, 92, 0.7)",
  },
  controlBarButtons: {
    selector: ".vjs-button, .vjs-play-progress .vjs-slider-bar",
    property: "color",
    color: "#ffffff",
  },
  controlBarText: {
    selector: ".vjs-time-control, .vjs-live-control",
    property: "color",
    color: "#ffffff",
  },
};

export default theme_options;
