import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Libs
import videojs, { VideoJsPlayer } from "video.js";

// CSS
import "./App.scss";
import "video.js/dist/video-js.css";

// Plugins
// CSS
import "@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css";
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";
import "videojs-contextmenu-ui/dist/videojs-contextmenu-ui.css";
import "videojs-hls-quality-selector/dist/videojs-hls-quality-selector.css";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "videojs-vr/dist/videojs-vr.css";
import "videojs-ima/dist/videojs.ima.css";
import "videojs-vtt-thumbnails/dist/videojs-vtt-thumbnails.css";
// Overrule some plugin styles
import "./js-videojs.scss";

// JS
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import "videojs-vr/dist/videojs-vr";
import "videojs-vtt-thumbnails";
import "videojs-contrib-ads";
import "videojs-ima";
import "./JsAds";

// Local modules
import PrivacyPlayerPostMessageAPI from "./PrivacyPlayerPostMessageAPI";
import theme_options from "./PrivacyPlayerDefaultTheme";

// @ts-ignore
import noImage from "./assets/images/noimageavailable_cropped.png";
import Button = videojs.Button;

require("@silvermine/videojs-chromecast")(videojs);
require("@silvermine/videojs-airplay")(videojs);
require("videojs-contextmenu-ui");
require("videojs-seek-buttons");
// End Plugins

// Languages
let languages = require("@cospired/i18n-iso-languages");
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
languages.registerLocale(require("@cospired/i18n-iso-languages/langs/nl.json"));

// List of all possible controlbar items
let control_bar_options = {
  playPause: "playToggle",
  seekBack: "seekBack",
  seekForward: "seekForward",
  volume: "volumePanel",
  currentTime: "currentTimeDisplay",
  progressBar: "progressControl",
  remainingTime: "remainingTimeDisplay",
  chromecast: "ChromecastButton",
  airplay: "AirPlayButton",
  playbackRate: "playbackRateMenuButton",
  chapters: "chaptersButton",
  description: "descriptionsButton",
  subtitles: "subsCapsButton",
  audiotracks: "audioTrackButton",
  quality: "QualityButton",
  pip: "pictureInPictureToggle",
  fullscreen: "fullscreenToggle",
};

function PrivacyPlayer(props: any) {
  const player_node_ref = useRef<HTMLVideoElement>(null);
  const player = useRef<VideoJsPlayer>();
  const api = useRef<any>();

  const current_language = "en";

  // Player options from props
  const p_startoffset = props.startoffset;
  const p_autoplay_enabled = props.autoplay;
  const p_loop_enabled = props.loop;
  const p_360vr_enabled = props.vr360;
  const p_events = props.events;
  const p_debug = props.debug;
  const p_optoutadvertising = props.optoutadvertising;
  const p_mute = props.mute;
  const a_type = props.type;
  const a_file = props.file;
  const a_subtitles = props.subtitles;
  const a_thumbnail = props.thumbnail;
  const a_posterframe = props.poster;
  const a_account = props.account;
  const a_service = props.service;
  const a_token = props.token;
  const a_vastxml = props.vastxml;

  const use_staging = props.use_staging;

  const l_dynamic_play_button = props.dynamic_play_button;
  const l_dynamic_poster_frame = props.dynamic_poster_frame;

  // State
  const [player_title, setPlayerTitle] = useState("");
  const [player_description, setPlayerDescription] = useState("");
  const [player_suggestions, setPlayerSuggestions] = useState([]);

  const [display_title, setDisplayTitle] = useState(true);
  const [display_endcap, setDisplayEndcap] = useState(false);

  const [player_initialized, setPlayerInitialized] = useState(false);

  const [sources, setSources] = useState<JsAssetSources>([]);

  const [ad_only_mode, setAdOnlyMode] = useState(false);
  const [ad_only_show_endcap, setAdOnlyShowEndcap] = useState(false);

  // Build RRR url based on staging
  const rrr_url = useMemo(() => {
    if (use_staging) {
      return "acceptance.takeoff.jetstre.am/";
    }
    return "takeoff.jetstre.am/";
  }, [use_staging]);

  /**
   * Get extra params from the url we don't use for ourselves and return them as
   * an object. You can manually add this to the URLs that get send to the
   * service.
   *
   * For example an &utm_source=google will be added to the request url for the
   * player. You can then find those back in the access logs.
   *
   * @returns {Object} extra params
   */
  function getExtraParams() {
    let data: any = {};
    const window_search_params = new URLSearchParams(window.location.search);
    const our_params = [
      "account",
      "file",
      "type",
      "service",
      "token",
      "sub",
      "protocol",
      "output",
      "thumbnails",
      "autostart",
      "hidecontrols",
      "repeat",
      "360vr",
      "offline_detect",
      "poster",
      "vastxml",
      "events",
      "st",
      "debug",
      "dynamic_play_button",
      "dynamic_poster_frame",
      "dynamic_vast",
      "staging",
      "ooa",
      "no-ext",
    ];
    window_search_params.forEach((value, key) => {
      if (!our_params.includes(key)) {
        // Not in list, add to data
        data[key] = value;
      }
    });
    return data;
  }

  /**
   * Constructs the playlist url for the video element.
   * @param file the asset name
   * @param type type of asset. live, streaming, download
   * @param service service to be used, wowza, apache, nginx
   * @param token Computed security token
   * @param extra_params extra params to be added to the url
   * @returns {URLSearchParams}
   */
  function constructTakeoffParameters(
    file: string,
    type: string,
    service: string,
    token: string | undefined,
    extra_params: object = {},
  ) {
    let data: any = {
      account: a_account,
      file: file,
      type: type,
      service: service,
      protocol: "https", // @todo do we still need http, or use any other protocol?
      token: undefined,
      port: undefined,
      output: undefined,
    };

    // send state to console

    if (!file) {
      return new URLSearchParams();
    }

    if (token) {
      data.token = token;
    }
    if (data.service === "icecast") {
      data.port = data.protocol === "https" ? 8001 : 8000;
      data.output = "pls";
    } else {
      //let extension = file.split('.').pop().toLowerCase();
      data.output = data.type === "download" ? "download" : "playlist.m3u8"; // @Todo: left mpd option out
    }

    // Add in the extra parameters
    Object.entries(extra_params).forEach(([key, value]) => {
      data[key] = value;
    });

    // Pass on "non ours" params
    Object.entries(getExtraParams()).forEach(([key, value]) => {
      data[key] = value;
    });

    return new URLSearchParams(data);
  }

  /**
   * Constructs a download (default) type of URL. Always goes to Apache service.
   * @param file
   * @param output
   * @returns {string}
   */
  function constructAssetUrl(file: string, output = "download") {
    const search_params = constructTakeoffParameters(
      file,
      "download",
      "apache",
      undefined,
      { output: output },
    );
    return "https://" + rrr_url + "?" + search_params.toString();
  }

  /**
   * See if a controlbar item is currently visible
   * @param item
   * @returns {boolean}
   */
  function controlBarButtonIsVisible(item: string) {
    // Check for player and controlbar
    if (
      player.current === undefined ||
      player.current.controlBar === undefined
    ) {
      return false;
    }

    if (item === "ChromecastButton") {
      let element = document.querySelector(".vjs-chromecast-button");
      if (element) {
        return !element.classList.contains("vjs-hidden");
      }
      return false;
    }
    if (item === "QualityButton") {
      let element = document.querySelector(".vjs-quality-selector");
      if (element) {
        return !element.classList.contains("vjs-hidden");
      }
      return false;
    }
    if (item === "AirPlayButton") {
      let element = document.querySelector(".vjs-airplay-button");
      if (element) {
        return !element.classList.contains("vjs-hidden");
      }
      return false;
    }
    if (item in player.current.controlBar) {
      // @ts-ignore No clue how to solve this
      return !player.current.controlBar[item].hasClass("vjs-hidden");
    }
    return false;
  }

  /**
   * Set the color of a theme element. can be any CSS property.
   * @param item
   * @param value
   */
  function setThemeColor(item: string, value: string) {
    let itemObj = theme_options[item];
    document.querySelectorAll(itemObj["selector"]).forEach((element) => {
      // @ts-ignore
      element.style[itemObj["property"]] = value;
    });
  }

  /**
   * Set an image as play button. This must be a full URL.
   * @param url
   */
  function setPlayButtonImageUrl(url: string) {
    let el = document.querySelector(".vjs-big-play-button") as HTMLElement;
    if (el) {
      if (url) {
        el.style.backgroundImage = "url(" + url + ")";
      } else {
        // For if you want to hide the play button
        el.style.backgroundImage = "none";
      }
    }
    showPlayButton();
  }

  /**
   * Sets the visibility of the big play button
   */
  function showPlayButton() {
    let el = document.querySelector(".vjs-big-play-button") as HTMLElement;
    if (el) {
      el.style.visibility = "visible";
      el.style.opacity = "1";
    }
  }

  /**
   * Set a poster frame. This must be a full URL.
   * @param url
   */
  function setPosterFrameUrl(url: string) {
    if (player.current !== undefined) {
      player.current.poster(url);
    }
  }

  /**
   * Show or hide a control bar item. "show" defaults to true.
   * @param item
   * @param show
   */
  function controlBarButtonShow(item: string, show = true) {
    if (
      item === "ChromecastButton" ||
      item === "QualityButton" ||
      item === "AirPlayButton"
    ) {
      let selector = ".vjs-chromecast-button";

      // Change to chromecast button it is's not quality
      if (item === "QualityButton") {
        selector = ".vjs-quality-selector";
      }
      if (item === "AirPlayButton") {
        selector = ".vjs-airplay-button";
      }
      let element = document.querySelector(selector);
      if (!element) {
        return false;
      }

      if (show) {
        element.classList.remove("vjs-hidden");
      } else {
        element.classList.add("vjs-hidden");
      }
      return false;
    }

    if (show && player.current && item in player.current.controlBar) {
      // @ts-ignore
      player.current.controlBar[item].show();
    } else {
      // @ts-ignore
      player.current.controlBar[item].hide();
    }
  }

  /**
   * Fetches the current state po the control bar by going through every button option.
   * @returns {{}}
   */
  function getControlBarButtonState() {
    let state = {};
    Object.keys(control_bar_options).forEach(
      (item) =>
        // @ts-ignore
        (state[item] = controlBarButtonIsVisible(control_bar_options[item])),
    );

    return state;
  }

  /**
   * Enables the API object and attaches the callbacks, so it can control the player
   * @private
   */
  let _enableApi = () => {
    api.current = new PrivacyPlayerPostMessageAPI(
      player.current,
      getControlBarButtonState,
      controlBarButtonShow,
      setThemeColor,
      setPlayButtonImageUrl,
      setPosterFrameUrl,
      setAssetByFilename,
      p_debug,
    );
  };

  /**
   * Fetches and sets the metadata in the state, so it can be displayed.
   * @type {(function(*=, *=, *=): void)|*}
   */
  const initMetaData = useCallback(
    (source: JsAssetSource) => {
      // TODO Refactor to constructTakeoffParameters
      let data = {
        account: a_account,
        file: source.src_filename,
        type: source.src_type,
        service: source.src_service,
        output: source.src_service === "apache" ? "download" : "playlist.m3u8",
        format: "jsonp",
      };

      const searchParams = new URLSearchParams(data);

      let url = "https://" + rrr_url + "?" + searchParams.toString();

      fetch(url)
        .then((response) => response.text())
        .then((data) => {
          let json_str = data;
          if (json_str.substring(0, 1) === "(") {
            // strip the ( ) from the data
            json_str = data.substring(1, data.length - 1);
          }
          const response = JSON.parse(json_str);
          if (response.hasOwnProperty("title")) {
            setPlayerTitle(response.title);
          }
          if (response.hasOwnProperty("description")) {
            setPlayerDescription(response.description);
          }
          if (response.hasOwnProperty("suggestions")) {
            setPlayerSuggestions(response.suggestions);
          }
        });
    },
    [a_account, rrr_url],
  );

  /**
   * Sets up a complete asset in the current player that is shown. This is
   * split out of the initial setup of an asset in the useEffect that
   * initializes the video player.
   * The reasoning is that we can now switch assets without reloading the entire
   * page. This is needed when you want a fullscreen experience that is not
   * interrupted when switching assets. Also, AirPlay and Chromecast benefit from
   * this change.
   * @param source
   * @param subtitles
   * @param chapters
   * @param thumbnails
   * @param poster_frame
   * @param extra_params
   */
  function setAsset(
    source: JsAssetSource,
    subtitles: Array<string>,
    chapters: string,
    thumbnails: string,
    poster_frame: string,
  ) {
    if (player.current === undefined) {
      return;
    }

    let was_playing = true;
    if (player.current.paused()) {
      was_playing = false;
    }

    // Add Video track
    const url = source.src;
    // extract file from url
    let file = url.split("/").pop()?.split("?")[0];
    let type = source.src_type;
    let service = source.src_service;

    if (type === "download") {
      let extension = file?.split(".").pop()?.toLowerCase();
      let content_type = "video/mp4";
      if (extension === "mp3") {
        content_type = "audio/mp3";
      } else if (extension === "aac") {
        content_type = "audio/aac";
      }
      player.current.src({ type: content_type, src: url });
    } else if (type === "live" && service === "icecast") {
      player.current.src({ type: "audio/mpeg", src: url });
    } else if (type === "streaming" || type === "live") {
      player.current.src({ type: "application/x-mpegURL", src: url });
    } else {
      player.current.src(url);
    }

    // Add Subtitle tracks
    setSubtitles(subtitles);
    // Add Chapters* (new feature)
    setChapters(chapters);
    // Add thumbnails
    setThumbnails(thumbnails);
    // Add poster frame
    setPosterFrame(poster_frame);
    // Metadata
    initMetaData(source);
    // More?

    // Set seek buttons
    if (type !== "live") {
      // No seeking for live sources
      // @ts-ignore
      if (!player.current.controlBar["seekForward"]) {
        // Only enable when not yet in the control bar
        // @ts-ignore
        player.current.seekButtons({
          forward: 10,
          back: 10,
        });
      }
    }

    // Resume playing
    if (was_playing) {
      player.current.play();
    }
  }

  function setAssetByFilename(
    file: string,
    type: string,
    service: string,
    subtitles: Array<string>,
    chapters: string,
    thumbnails: string,
    poster_frame: string,
    token: any,
    extra_params = {},
  ) {
    const params = constructTakeoffParameters(
      file,
      type,
      service,
      token,
      extra_params,
    );
    params.append("format", "all_servers_json");

    fetchSources(params).then((sources) => {
      if (sources.length > 0) {
        // Set the first source
        setAsset(sources[0], subtitles, chapters, thumbnails, poster_frame);
      }
    });
  }

  /**
   * Not implemented chapter function
   * @param chapters
   */
  function setChapters(chapters: string) {
    if (p_debug) {
      console.info("setChapters not implemented yet");
    }
  }

  /**
   * Setts the thumbnail track (the thing when you hover the timeline).
   *
   * If you set it to false, it removes the thumbnails.
   * @param thumbnails
   */
  function setThumbnails(thumbnails: string) {
    // @ts-ignore
    if (typeof player.current.vttThumbnails.src === "function") {
      if (!thumbnails) {
        // @ts-ignore
        player.current.vttThumbnails.resetPlugin();
      } else {
        // @ts-ignore
        player.current.vttThumbnails.src(constructAssetUrl(thumbnails));
      }
    } else {
      if (thumbnails) {
        // @ts-ignore
        player.current.vttThumbnails({ src: constructAssetUrl(thumbnails) });
      }
    }
  }

  /**
   * Sets the poster frame of the current player.
   *
   * If you set it to false, it removes the poster frame.
   * @param poster_frame
   */
  function setPosterFrame(poster_frame: string) {
    if (player.current === undefined) {
      return;
    }
    if (!poster_frame) {
      player.current.poster();
    } else {
      player.current.poster(constructAssetUrl(poster_frame));
    }
  }

  /**
   * Sets the list of subtitle tracks you have provided.
   * Note: It has to be a list of strings containing the filenames of the subs.
   *
   * If you set it to false, it removes all subtitle tracks.
   * @param subtitles
   */
  function setSubtitles(subtitles: Array<string>) {
    if (player.current === undefined) {
      return;
    }

    removeSubtitles();
    if (!subtitles) {
      return;
    }
    const regex = /_(\w{2})\.vtt/;
    let m;
    subtitles.forEach((subtitle) => {
      let lang = "";
      if ((m = regex.exec(subtitle)) !== null) {
        lang = m[1].toLowerCase();
      }
      let label = languages.getName(lang, current_language);
      // @ts-ignore
      player.current.addRemoteTextTrack(
        {
          kind: "subtitles",
          srclang: lang,
          label: label,
          src: constructAssetUrl(subtitle),
        },
        true,
      );
    });
  }

  /**
   * Removes the subtitle tracks from the current player.
   */
  function removeSubtitles() {
    if (player.current === undefined) {
      return;
    }
    let tracks = player.current.textTracks();
    let count = tracks.length;
    for (let i = count - 1; i >= 0; i--) {
      let track = tracks[i];
      if (track.kind === "subtitles") {
        // Static typing has learned us that removeTrack is not a function
        // TODO: Fix this
        // @ts-ignore
        tracks.removeTrack(track);
      }
    }
  }

  // TODO: THis can probablu be removed because VR now seems to work
  function videojs_error_handler() {
    if (player.current === undefined) {
      return;
    }
    let error = player.current.error();
    // @ts-ignore
    if (error.code === "web-vr-hls-cors-not-supported") {
      console.error("VR issue, redirecting...");
      window.location.href =
        "https://rrr.sz.xlcdn.com/" + window.location.search;
    }
  }

  type JsAssetSource = {
    label: string;
    type: string;
    src: string;
    src_type: string;
    src_service: string;
    src_filename: string;
  };

  type JsAssetSources = Array<JsAssetSource>;

  const fetchSources = async (params: URLSearchParams) => {
    params.append("format", "all_servers_json");
    const extra_params = new URLSearchParams(getExtraParams());

    const source_options: JsAssetSources = [];
    const response = await fetch(
      "https://" + rrr_url + "?" + params.toString(),
    );
    if (!response.ok) {
      console.error("Error fetching sources");
      return source_options;
    }
    const data = await response.json();
    data.forEach((source: any) => {
      let url = new URL(source.streamURL);
      extra_params.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      source_options.push({
        label: source.serverurl,
        type: source.servertype,
        src: url.toString(),
        src_type: a_type,
        src_service: a_service,
        src_filename: a_file,
      });
    });
    return source_options;
  };

  /*
    This fucntion get called when the sources are ready
  */
  useEffect(() => {
    // Ad only init mode
    if (!ad_only_mode) {
      // to prevent doing things if we are not in ad only mode
      return;
    }

    const options = {
      controls: true,
      //preload: "default",
      techOrder: ["html5"],
      autoplay: false,
      muted: p_mute,
      controlBar: {
        volumePanel: {
          inline: false,
        },
      },
    };

    // Reset the player
    if (player.current !== undefined) {
      //player.current.dispose(); // Not disposing because it cleans up the video tag too?
      player.current = undefined;
    }

    // Initialize the video.js player object
    // @ts-ignore
    player.current = videojs(player_node_ref.current, options, () => {
      // Player ready
      const [ad_slot, publisher] = p_optoutadvertising.split(",");

      if (player.current === undefined) {
        return;
      }

      setPlayerInitialized(true);

      player.current.src("/void.mp4");

      if (ad_slot === "-" && publisher === undefined) {
        console.info("Lazy ad mode, no ads loaded");
        player.current.on("ads-ad-load-vaststing", (event) => {
          console.info("Going to load VAST String");
          // @ts-ignore
          player.current.jsAds({
            vastXml: event.vastXml,
            adOnlyMode: true,
            autoPlay: p_autoplay_enabled,
          });
          player?.current?.src("/void.mp4");
        });
      } else {
        // @ts-ignore
        player.current.jsAds({
          adSlot: ad_slot,
          publisher: publisher,
          adOnlyMode: true,
          autoPlay: p_autoplay_enabled,
        });
      }

      player.current.on("ads-ad-ended", () => {
        console.info("React Ad ended");
        setAdOnlyShowEndcap(true);
      });

      // Set event handlers
      if (p_events && !api.current) {
        _enableApi();
      }

      console.info("Player loaded in Ad Mode");
    });
  }, [ad_only_mode]);

  useEffect(() => {
    function _determine_preload() {
      if (p_autoplay_enabled) {
        return "auto";
      }
      if (a_type === "live" && a_posterframe) {
        return "none";
      }
      return "auto";
    }

    if (sources.length === 0) {
      return;
    }

    const source = sources[0];
    const options = {
      controls: true,
      preload: _determine_preload(),
      techOrder: ["chromecast", "html5"],
      plugins: {
        chromecast: {
          buttonPositionIndex: 9,
        },
        airPlay: {
          buttonPositionIndex: 9,
        },
      },
      autoplay: p_autoplay_enabled, // We do play after setting the source
      muted: p_autoplay_enabled | p_mute, // Autoplay also means mute
      loop: p_loop_enabled,
      html5: {
        vhs: {
          handleManifestRedirects: true,
          overrideNative: !videojs.browser.IS_SAFARI, // Not for Safari but all other browsers should override.
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      },
      controlBar: {
        volumePanel: {
          inline: false,
        },
      },
      liveui: true, // This enables DVR controls
    };

    // Reset the player
    if (player.current !== undefined) {
      player.current.dispose();
      player.current = undefined;
    }

    // Initialize the video.js player object
    // @ts-ignore
    player.current = videojs(player_node_ref.current, options, () => {
      if (p_debug) {
        // When in debug mode is very handy to be able to get to the player
        // easily
        (window as any).player = player.current;
      }

      if (player.current === undefined) {
        return;
      }

      // Activate plugins
      // @ts-ignore
      player.current.airPlay(); //@TODO: Should not be needed

      if (
        process.env.REACT_APP_PP_CONTEXT_MENU_URL ===
        "https://jet-stream.com/products/player/"
      ) {
        // @ts-ignore
        player.current.contextmenuUI({
          content: [
            {
              href: "https://jet-stream.com/products/player/",
              label: p_events
                ? "Jet-Stream Privacy Player Pro"
                : "Jet-Stream Privacy Player Free",
            },
          ],
        });
      } else {
        // @ts-ignore
        player.current.contextmenuUI({
          content: [
            {
              href: process.env.REACT_APP_PP_CONTEXT_MENU_URL,
              label: process.env.REACT_APP_PP_CONTEXT_MENU_LABEL,
            },
          ],
        });
      }

      // Set player to specific timecode
      if (p_startoffset > 0) {
        // @ts-ignore
        player.current.currentTime(p_startoffset);
      }

      // No Play button if dynamic (it will come later from parent)
      if (l_dynamic_play_button) {
        setPlayButtonImageUrl("");
      } else {
        // if no custom button, display build in one
        showPlayButton();
      }

      // Poster frame, except if it is dynamic
      let poster_frame = undefined;
      if (a_posterframe && !l_dynamic_poster_frame) {
        poster_frame = a_posterframe;
      }

      // Subtitles need to be a array/list
      let subtitles = [];
      if (a_subtitles) {
        subtitles = a_subtitles.split(",");
      }

      // Thumbnails casting
      let thumbnails = undefined;
      if (a_thumbnail) {
        thumbnails = a_thumbnail;
      }

      // Set the asset and its parts
      setAsset(source, subtitles, "", thumbnails, poster_frame);

      // Enable quality selector
      // (!) Needs to go after setting the source
      // @ts-ignore
      player.current.hlsQualitySelector();

      // Setup VR
      if (p_360vr_enabled) {
        // @ts-ignore
        if (!player.current.mediainfo) {
          // @ts-ignore
          player.current.mediainfo = {};
        }

        // @ts-ignore
        if (!player.current.mediainfo.projection) {
          // @ts-ignore
          player.current.mediainfo.projection = "360";
        }

        // @ts-ignore
        player.current.vr({ projection: "AUTO" });
      }

      if (a_vastxml) {
        let imaOptions = {
          adTagUrl: constructAssetUrl(a_vastxml),
        };
        // @ts-ignore
        player.ima(imaOptions);
      }

      if (p_optoutadvertising) {
        const [ad_slot, publisher] = p_optoutadvertising.split(",");
        player.current.play();
        // @ts-ignore
        player.current.jsAds({
          adSlot: ad_slot,
          publisher: publisher,
        });
      }

      // Set event handlers
      if (p_events && !api.current) {
        _enableApi();
      }

      // Display the tile
      player.current.on("play", () => {
        setDisplayTitle(false);
        setDisplayEndcap(false);
      });
      player.current.on("pause", () => {
        setDisplayTitle(true);
      });
      player.current.on("ended", () => {
        setDisplayTitle(false);
        setDisplayEndcap(true);
      });
    });

    player.current.on("error", videojs_error_handler);

    // Set initialized state so player will be visible, 50ms to give it time
    // to load the bg image
    setTimeout(() => {
      setPlayerInitialized(true);
    }, 50);

    return () => {
      if (player.current) {
        player.current.dispose();
      }
      if (api.current) {
        // If not enabled, no need to dispose of it.
        // @ts-ignore
        api.current.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sources]);

  /**
   * Bootstraps the entire player by loading the sources.
   */
  useEffect(
    () => {
      const params = constructTakeoffParameters(
        a_file,
        a_type,
        a_service,
        a_token,
        {},
      );
      // If all required parameters are not set, do nothing
      if (!a_file && !a_type && !a_service && !p_optoutadvertising) {
        console.info("No takeoff parameters, not loading sources.");
        return;
      }
      if (!a_file && !a_type && !a_service && p_optoutadvertising) {
        console.info("Ad only mode.");
        setAdOnlyMode(true);
        return;
      }

      // Regular mode
      fetchSources(params).then((sources) => {
        setSources(sources);
      });
    },
    // eslint-disable-next-line
    [], // No deps because this may only run on initial render (componentDidMount)
  );

  function Title() {
    return (
      <>
        {player_title && (
          <div className={"row gx-4"}>
            <div className={"col"}>
              <h1>{player_title}</h1>
              <p>{player_description}</p>
            </div>
          </div>
        )}
      </>
    );
  }

  function Endcap() {
    return (
      <>
        {player_suggestions.length > 0 && (
          <>
            <div className={"row"}>
              <div className={"col"}>
                <div className={"endcap_title"}>
                  <h1>You may also like...</h1>
                </div>
              </div>
            </div>
            <div className={"row gx-4"}>
              {player_suggestions.map((element: any, index) => (
                <div
                  className={"col player_suggestions"}
                  onClick={() => {
                    if (!player.current) {
                      return;
                    }
                    setDisplayEndcap(false);
                    let service =
                      element.type === "streaming" ? "wowza" : "apache";
                    const new_src =
                      "https://" +
                      rrr_url +
                      "?" +
                      constructTakeoffParameters(
                        element.rediraptorfilename,
                        element.type,
                        service,
                        element.token,
                        {},
                      ).toString();
                    setAsset(
                      {
                        src: new_src,
                        src_service: service,
                        src_type: element.type,
                        src_filename: element.rediraptorfilename,
                        type: "-",
                        label: "RRR",
                      },
                      [],
                      "",
                      "",
                      "",
                    );
                    // Play the new video
                    player.current.play();
                  }}
                  key={index}
                >
                  <h1>{element.title}</h1>
                  <img
                    className={"img-fluid"}
                    src={element.image ? element.image : noImage}
                    alt={element.alt}
                  />
                  <div className={"player_suggestions_overlay"}>
                    <h2>
                      {new Date(element.duration * 1000)
                        .toISOString()
                        .substring(11, 8)}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  }

  function AdEndcap() {
    function clickAd() {
      // pass through click event.
      // when the 'more information' button is clicked, the video click-event
      // must be triggered (among other things opens advertiser info page)
      if (!player.current) {
        return;
      }
      player_node_ref.current?.click();
    }
    function replay() {
      if (!player.current) {
        return;
      }

      setAdOnlyShowEndcap(false);
      player.current.play();
      player.current.on("ended", () => {
        setAdOnlyShowEndcap(true);
      });
      //window.location.reload();
    }

    return (
      <div className={"position-absolute top-50 start-50 translate-middle"}>
        <input
          type={"button"}
          className={"btn btn-light"}
          onClick={clickAd}
          value={"More information"}
        />
        <br />
        <input
          type={"button"}
          className={"btn btn-link pt-3"}
          onClick={replay}
          value={"Replay"}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        opacity: player_initialized ? "1" : "0",
      }}
    >
      <div
        className={"container-fluid pt-3 title_overlay"}
        style={{
          visibility: display_title ? "visible" : "hidden",
          opacity: display_title ? 1 : 0,
          transition: display_title
            ? "opacity 0.2s linear"
            : "visibility 0s 0.2s, opacity 0.2s linear",
        }}
      >
        <Title />
      </div>
      <div
        className={"container-fluid pt-3 px-4 endcap_overlay"}
        style={{
          visibility: display_endcap ? "visible" : "hidden",
          opacity: display_endcap ? 1 : 0,
          transition: display_endcap
            ? "opacity 0.2s linear"
            : "visibility 0s 0.2s, opacity 0.2s linear",
        }}
      >
        <Endcap />
      </div>
      <div
        className={"container-fluid px-4 endcap_overlay ad_endcap_overlay"}
        style={{
          visibility: ad_only_show_endcap ? "visible" : "hidden",
          opacity: ad_only_show_endcap ? 1 : 0,
          transition: ad_only_show_endcap
            ? "opacity 0.2s linear"
            : "visibility 0s 0.2s, opacity 0.2s linear",
        }}
      >
        <AdEndcap />
      </div>
      <video
        ref={player_node_ref}
        className={"video-js vjs-big-play-centered"}
        controls
        playsInline
        preload="auto"
        crossOrigin={"anonymous"}
      >
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to
          a web browser that
          <a
            href="https://videojs.com/html5-video-support/"
            target="_blank"
            rel="noreferrer"
          >
            supports HTML5 video
          </a>
        </p>
      </video>
    </div>
  );
}

export default PrivacyPlayer;
export { control_bar_options, theme_options };
