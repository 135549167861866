// Load some external libs (or dont)
import "./NoLoadExternal";

// IE 11 compatibility
import "url-search-params-polyfill";
import "react-app-polyfill/ie11";

import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";

import React from "react";
import { createRoot } from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

/**
 * @param {string} hostname
 */
const sentryEnvironment = function (hostname) {
  if (hostname === "acceptance.player.jetstre.am") {
    return "acceptance";
  }
  return process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV;
};

if (process.env.REACT_APP_SENTRY_DSN || undefined) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: sentryEnvironment(window.location.hostname),
    release: process.env.REACT_APP_SENTRY_RELEASE || undefined,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({ levels: ["error"] }),
    ],

    tracesSampler: () =>
      sentryEnvironment(window.location.hostname) === "production" ? 0.001 : 1,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
