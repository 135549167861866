import videojs from "video.js";
import { XMLParser } from "fast-xml-parser";

const Plugin = videojs.getPlugin("plugin");
// eslint-disable-next-line
const version = "1.0.0";

// bottom type, we're not implementing this
type Nope = {};
type Pricing = Nope;
type Survey = Nope;

type TODO = {};
type Impression = click;

type AnyURI = string;

type Time = string;

type Category = {
  text: string;
  _authority: AnyURI;
};

type AdDefinitionBase = {
  AdSystem:
    | string
    | {
        text: string;
        _version: string;
      };
  Impression: Impression | Impression[];
  Pricing: Pricing | undefined;
};

type Creatives = {
  Creative: Creative | Creative[];
};

type CreativeBase = TODO;

type Creative = CreativeBase & {
  CompanionAds: Nope | undefined;
  CreativeExtensions: Nope | undefined;
  Linear: Linear | undefined;
  NonLinearAds: Nope | undefined;
};

type TrackingEventTypes =
  | "mute"
  | "unmute"
  | "pause"
  | "resume"
  | "skip"
  | "playerExpand"
  | "playerCollapse"
  | "start"
  | "firstQuartile"
  | "midpoint"
  | "thirdQuartile"
  | "complete"
  | "acceptInvitationLinear"
  | "timeSpentViewing"
  | "progress"
  | "creativeView"
  | "acceptInvitation"
  | "adExpand"
  | "adCollapse"
  | "minimize"
  | "close"
  | "overlayViewDuration"
  | "otherAdInteration"
  | "impression";

type Tracking = {
  text: AnyURI;
  _event: TrackingEventTypes;
  _offset: string | undefined;
};

type TrackingEvents = {
  Tracking: Tracking | Tracking[] | undefined;
};

type click =
  | AnyURI
  | {
      text: AnyURI;
      _id: string | undefined;
    };
type VideoClicksBase = {
  ClickTracking: click | click[] | undefined; // URL to request for tracking purposes when user clicks on the video.
  CustomClick: click | click[] | undefined; // URLs to request on custom events such as hotspotted video.
};
type VideoClicksInline = VideoClicksBase & {
  ClickThrough: click | undefined;
};

function clickUrl(c: click): URL {
  if (typeof c === "string") {
    return new URL(c);
  }
  return new URL(c.text);
}
function makeArray<Type>(item: Type | Type[] | undefined): Type[] {
  if (item === undefined) {
    return [];
  }
  if (Array.isArray(item)) {
    return item;
  }
  return [item];
}

type MediaFile = {
  text: AnyURI;
  _id: string | undefined;
  _delivery: "streaming" | "progressive";
  _type: string;
  _width: number;
  _height: number;
  _codec: string | undefined;
  _bitrate: number | undefined;
  _minBitrate: number | undefined;
  _maxBitrate: number | undefined;
  _scalable: boolean | undefined;
  _maintainAspectRatio: boolean | undefined;
  _apiFramework: string | undefined;
};

type LinearBase = {
  Icons: Nope | undefined;
  TrackingEvents: TrackingEvents | undefined;
  _skipoffset: string;
};
type Linear = LinearBase & {
  AdParameters: Nope | undefined;
  Duration: Time;
  MediaFiles: {
    MediaFile: MediaFile;
    Mezzanine: Nope | undefined;
    InteractiveCreativeFile: Nope | Nope[] | undefined;
  };
  VideoClicks: VideoClicksInline;
};

type Inline = AdDefinitionBase & {
  AdTitle: string;
  Advertiser: string | undefined;
  Category: Category | Category[] | undefined;
  Creatives: Creatives;
  Impression: string | null;
  Description: string | undefined;
  Survey: Survey | undefined;
};

type InlineAd = {
  InLine: Inline;
  _id: string | undefined;
  _sequence: number | undefined;
  _conditionalAd: boolean | undefined;
};

// We haven't implemented WrapperAd
type WrapperAd = {
  Wrapper: Nope;
};

type Ad = InlineAd | WrapperAd;

type RawVASTFile = {
  Ad: Ad | Ad[] | undefined;
  _version: string;
};

export class VASTParser {
  vast: RawVASTFile;
  impression_url: string | null = null;
  constructor(content: string) {
    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: "_",
      textNodeName: "text",
    };
    this.vast = new XMLParser(options).parse(content).VAST;
  }

  ads() {
    return makeArray(this.vast.Ad);
  }
  inlineAds(): InlineAd[] {
    return this.ads()
      .map((a) => a as InlineAd)
      .filter((a) => a.InLine !== undefined);
  }

  firstCreative(ad: InlineAd): Creative {
    const c = ad.InLine.Creatives.Creative;
    if (Array.isArray(c)) {
      return c[0];
    }
    return c;
  }

  mediafiles(ad: InlineAd): MediaFile[] {
    const ads = this.inlineAds();
    return ads
      .map((ad) => {
        const firstCreative = this.firstCreative(ad);
        // TODO one to many `Creative` elements may occur. For now we just use the first one
        const mediafiles = firstCreative.Linear?.MediaFiles.MediaFile;
        if (!Array.isArray(mediafiles)) {
          return [mediafiles];
        }
        return mediafiles;
      })
      .flat();
  }

  /**
   * This function returns undefined or a string with the URL to the MP4 file
   * @returns {string|undefined}
   */
  getPrerollMediafile(ad: InlineAd): string | undefined {
    let media_files = this.mediafiles(ad);

    if (media_files.length === 0) {
      return;
    }

    // Get all the MP4 files
    media_files = media_files.filter(
      (media_file) => media_file._type === "video/mp4" && media_file._bitrate,
    );
    // Sort by bitrate
    media_files.sort((a, b) => (a._bitrate ?? 0) - (b._bitrate ?? 0));
    // Get the highest bitrate
    let mp4_file = media_files.reverse()[0].text;
    if (!mp4_file) {
      return;
    }
    return mp4_file;
  }

  getImpresionEventUrl(ad: InlineAd) {
    return ad.InLine.Impression;
  }

  /**
   * Returns the tracking URL for the given event name
   */
  getTrackingEventUrl(ad: InlineAd, event_name: TrackingEventTypes) {
    if (event_name === "impression") {
      return this.getImpresionEventUrl(ad);
    }
    const creative = this.firstCreative(ad);
    if (creative.Linear?.TrackingEvents === undefined) {
      return;
    }

    let tracking_urls = makeArray(creative.Linear?.TrackingEvents?.Tracking);
    for (let tracking_event of tracking_urls) {
      if (tracking_event._event === event_name) {
        return tracking_event.text;
      }
    }
    return undefined;
  }

  getVideoClicksUrls(firstAd: InlineAd) {
    const creative = this.firstCreative(firstAd);
    if (!creative.Linear?.VideoClicks) {
      return undefined;
    }
    return {
      target_url: creative.Linear.VideoClicks.ClickThrough,
      tracking_url: creative.Linear.VideoClicks.ClickTracking,
    };
  }
}

/**
 * A video.js plugin that uses the contrib-ads plugin to play VAST ads.
 * it is a more privacy-friendly alternative to the IMA library.
 *
 * @param {Object} options
 */
class JsAdsPlugin extends Plugin {
  firstQuartileEventSent = false;
  midpointEventSent = false;
  thirdQuartileEventSent = false;

  adSlot: string | null = null;
  publisher: number | null = null;

  vastFile: VASTParser | undefined;
  vast_xml: string | undefined;

  player: videojs.Player;

  clickEventListener: EventListener | undefined;

  disposed = false;

  ad_only_mode = false;

  auto_play = false;

  dispose() {
    super.dispose();
    // Not sure if we need to reset the state back when disposing.
    // The behaviour is copied over from another plugin example from VideoJS.
    // this.firstQuartileEventSent = false;
    // this.midpointEventSent = false;
    // this.thirdQuartileEventSent = false;
    // this.adSlot = null;
    // this.publisher = null;
    // this.vastFile = undefined;
    // this.clickEventListener = undefined;
    //
    this.disposed = true;

    //videojs.log("JsAdsPlugin disposed.");
  }

  constructor(player: videojs.Player, options: any) {
    super(player, options);

    this.player = player;

    // Activate the ads plugin from contrib-ads
    if (!("ads" in player) || typeof player.ads !== "function") {
      throw new Error("videojs-contrib-ads not loaded");
    }
    player.ads(options);

    if (options.adSlot) {
      this.adSlot = options.adSlot;
    }
    if (options.publisher) {
      this.publisher = parseInt(options.publisher);
    }

    if (options.adOnlyMode) {
      this.ad_only_mode = options.adOnlyMode;
    }

    if (options.autoPlay) {
      this.auto_play = options.autoPlay;
    }

    if (options.vastXml) {
      this.vast_xml = options.vastXml;
    }

    player.on("contentchanged", async () => {
      await this.requestAds();
    });

    player.on("readyforpreroll", async () => {
      if (this.vastFile === undefined) {
        throw new Error("No VAST file parsed");
      }
      const firstInlineAd = this.vastFile.inlineAds()[0];
      if (firstInlineAd === undefined) {
        return undefined;
      }
      const pre_roll_src = this.vastFile.getPrerollMediafile(firstInlineAd);

      if (!pre_roll_src) {
        return;
      }

      if (!("ads" in player)) {
        throw new Error("videojs-contrib-ads is not loaded");
      }

      // @ts-expect-error
      player.ads.startLinearAdMode();

      // Set the source for the ad
      player.src(pre_roll_src);

      // send event when ad is playing to remove loading spinner
      player.one("adplaying", () => {
        this.sendTrackingEvent(firstInlineAd, "impression");
        this.sendTrackingEvent(firstInlineAd, "start");
        player.trigger("ads-ad-started");
      });

      // resume content when all your linear ads have finished
      player.one("adended", () => {
        this.sendTrackingEvent(firstInlineAd, "complete");

        if (!this.ad_only_mode) {
          // @ts-expect-error
          player.ads.endLinearAdMode();
          if (this.clickEventListener) {
            this.player.off("click", this.clickEventListener);
            this.clickEventListener = undefined;
          }
        }
      });
      // Fire this event multiple times. This is for if you replay an ad.
      player.on("adended", () => {
        player.trigger("ads-ad-ended");
      });

      const interval = setInterval(() => {
        const currentTime = player.currentTime();
        const duration = player.duration();
        const percent = (currentTime / duration) * 100;
        if (percent >= 25 && !this.firstQuartileEventSent) {
          this.firstQuartileEventSent = true;
          this.sendTrackingEvent(firstInlineAd, "firstQuartile");
        }
        if (percent >= 50 && !this.midpointEventSent) {
          this.midpointEventSent = true;
          this.sendTrackingEvent(firstInlineAd, "midpoint");
        }
        if (percent >= 75 && !this.thirdQuartileEventSent) {
          this.thirdQuartileEventSent = true;
          this.sendTrackingEvent(firstInlineAd, "thirdQuartile");
          clearInterval(interval);
        }
      }, 100);

      const clickURLs = this.vastFile.getVideoClicksUrls(firstInlineAd);
      if (clickURLs) {
        this.clickEventListener = (e) => {
          const target = e.target as HTMLElement;
          if (target.tagName !== "VIDEO") {
            // only handle clicks on the video
            return;
          }
          const tracking_urls = makeArray(clickURLs.tracking_url).map(clickUrl);
          for (const t of tracking_urls) {
            fetch(t, { mode: "no-cors" });
          }
          if (clickURLs.target_url) {
            window.open(clickUrl(clickURLs.target_url), "_blank");
          }
          return false;
        };
        this.player.on("click", this.clickEventListener);
      }
    });

    player.on("playing", function () {
      //videojs.log("playback began!");
    });

    // Finally load the ads for the first time
    this.requestAds();

    if (this.auto_play) {
      // @ts-expect-error
      player.play().catch((err) => {
        // Ignore errors
        console.info("Needs to be muted to play ads");
        player.muted(true);
        player.play();
      });
    }

    //videojs.log("JsAdsPlugin constructed.", this.player);
  }

  sendTrackingEvent(ad: InlineAd, event: TrackingEventTypes) {
    const url = this.vastFile!.getTrackingEventUrl(ad, event);
    if (!url) {
      return;
    }

    fetch(url, { mode: "no-cors" }).catch((err) => {
      // Ignore errors
    });
  }

  loadVast(content: string): void {
    //options.vastxml
    if (!this.isDisposed()) {
      // Parse the VAST file
      this.vastFile = new VASTParser(content);

      // Telling player that we are ready to play ads
      this.player.trigger("adsready");

      // Message VAST to upper layers
      this.player.trigger({
        type: "ads-vast-content",
        content: content,
      });
      // Check if we have an ad:
      if (this.vastFile.inlineAds().length > 0) {
        this.player.trigger("ads-vast-ad-detected");
      } else {
        this.player.trigger("ads-vast-ad-not-detected");
      }
    }
  }

  /**
   * Fetches VAST file and lets VASTParser parse it.
   * @returns {void}
   */
  requestAds(): void {
    console.debug("requestAds", this.vast_xml);
    // If plugin gets activated with XML string, load that.
    if (this.vast_xml !== undefined) {
      this.loadVast(this.vast_xml);
      return;
    }

    fetch(this.constructVastUrl())
      .then((response) => response.text())
      .then((content) => {
        this.loadVast(content);
      });
  }

  constructVastUrl() {
    return `https://adserving.optoutadvertising.com/hub/video?adSlot=${this.adSlot}&publisher=${this.publisher}`;
  }

  isDisposed() {
    return this.disposed;
  }
}

videojs.registerPlugin("jsAds", JsAdsPlugin);
