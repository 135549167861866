let params = new URLSearchParams(window.location.search);
let includeExternal = {
  cast: true,
  ima: true,
};

// don't load Google IMA SDK when Opt Out Advertising integration is active
if (params.has("ooa")) {
  includeExternal.ima = false;
}

if (params.has("no-ext")) {
  let no_ext = params.get("no-ext");
  if (no_ext.includes("cast")) {
    includeExternal.cast = false;
  }
  if (no_ext.includes("ima")) {
    includeExternal.ima = false;
  }
  if (no_ext.includes("all")) {
    for (let idx in includeExternal) {
      includeExternal[idx] = false;
    }
  }
}
if (includeExternal.cast) {
  // Chromecast
  document.write(
    '<script type="text/javascript" src="https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"></script>',
  );
}
if (includeExternal.ima) {
  // Advertising
  document.write(
    '<script type="text/javascript" src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>',
  );
}
