import PrivacyPlayer from "./PrivacyPlayer";

// CSS
import "./App.scss";

// Test URL: /?account=jetstreammanual&file=readysetrocknroll6.smil&type=streaming&service=wowza&output=player&sub=readysetrocknroll6_EN.vtt%2Creadysetrocknroll6_NL.vtt&thumbnails=readysetrocknroll6.vtt&poster=readysetrocknroll6.jpg
// Test advertisement URL: /?account=jetstreammanual&file=readysetrocknroll6.smil&type=streaming&service=wowza&output=player&sub=readysetrocknroll6_EN.vtt%2Creadysetrocknroll6_NL.vtt&thumbnails=readysetrocknroll6.vtt&poster=readysetrocknroll6.jpg&ooa=jetstreamtest,66
// Test advertisement URL: /?ooa=jetstreamtest,66&autostart=1

function App() {
  const url_params = new URLSearchParams(window.location.search);

  // Player options from URL and assing to consts
  const p_startoffset = url_params.has("st")
    ? parseInt(url_params.get("st"), 10)
    : 0;

  // Casting all "1" to true, else false;
  const p_autoplay_enabled = url_params.has("autostart")
    ? url_params.get("autostart") === "1"
    : false;
  const p_loop_enabled = url_params.has("repeat")
    ? url_params.get("repeat") === "1"
    : false;
  const p_360vr_enabled = url_params.has("360vr")
    ? url_params.get("360vr") === "1"
    : false;
  const p_events = url_params.has("events")
    ? url_params.get("events") === "1"
    : false;
  const p_debug = url_params.has("debug")
    ? url_params.get("debug") === "1"
    : false;
  const p_mute = url_params.has("mute")
    ? url_params.get("mute") === "1"
    : false;

  const p_optoutadvertising = url_params.has("ooa")
    ? url_params.get("ooa")
    : false;

  // Getting all the asset parameters
  const a_type = url_params.get("type");
  const a_file = url_params.get("file");
  const a_subtitles = url_params.get("sub");
  const a_thumbnail = url_params.get("thumbnails");
  const a_poster = url_params.get("poster");
  const a_account = url_params.get("account");
  const a_service = url_params.get("service");
  const a_vastxml = url_params.get("vastxml");
  const a_token = url_params.has("token") ? url_params.get("token") : false;

  // Loading behaviour changes
  const l_dynamic_play_button = url_params.has("dynamic_play_button");
  const l_dynamic_poster_frame = url_params.has("dynamic_poster_frame");

  // Enable staging load
  const use_staging = url_params.has("staging");

  return (
    <div className="App">
      <PrivacyPlayer
        startoffset={p_startoffset}
        autoplay={p_autoplay_enabled}
        loop={p_loop_enabled}
        vr360={p_360vr_enabled}
        events={p_events}
        debug={p_debug}
        type={a_type}
        file={a_file}
        subtitles={a_subtitles}
        thumbnail={a_thumbnail}
        poster={a_poster}
        account={a_account}
        service={a_service}
        token={a_token}
        vastxml={a_vastxml}
        optoutadvertising={p_optoutadvertising}
        dynamic_play_button={l_dynamic_play_button}
        dynamic_poster_frame={l_dynamic_poster_frame}
        mute={p_mute}
        use_staging={use_staging}
      />
    </div>
  );
}

export default App;
