import { control_bar_options } from "./PrivacyPlayer";

/**
 * The Privacy Player API communication layer
 * @param {any} player
 * @param {function} get_control_bar_button_state_callback
 * @param {function} control_bar_button_show_callback
 * @param {function} set_theme_color_callback
 * @param {function} set_play_button_image_url_callback
 * @param {function} set_poster_frame_url_callback
 * @param {function} set_asset_callback
 * @param {boolean} p_debug
 * @constructor
 */
function PrivacyPlayerPostMessageAPI(
  player,
  get_control_bar_button_state_callback,
  control_bar_button_show_callback,
  set_theme_color_callback,
  set_play_button_image_url_callback,
  set_poster_frame_url_callback,
  set_asset_callback,
  p_debug,
) {
  /**
   * Sets all the event messages that are needed to be emitted according to our docs.
   * It forms the bridge to our SDK as well so be mindful with changes.
   * @private
   */
  function _setEventMessengers() {
    if (player === null) {
      console.error("Can't set events if player is not initialized");
      return;
    }
    if (!window || !window.parent) {
      console.info("Events not initialized. No parent window detected.");
      return;
    }

    function _postMessage(event, data) {
      window.parent.postMessage({ event: event, data: data }, "*");
      if (p_debug) {
        console.debug("postmessage", event, data);
      }
    }

    // All simple events all at once
    [
      "error",
      "abort",
      "canplay",
      "canplaythrough",
      "emptied",
      "ended",
      "loadeddata",
      "pause",
      "play",
      "playing",
      "seeked",
      "seeking",
      "stalled",
      "suspend",
      "waiting",
      "adserror",
    ].forEach((element) => {
      player.on(element, () => {
        _postMessage(element, true);
      });
    });

    // More complex events
    player.on("ads-ad-started", () => {
      // Complex type because event name is not the same
      _postMessage("adstart", true);
    });
    player.on("ads-ad-ended", () => {
      // Complex type because event name is not the same
      _postMessage("adend", true);
    });
    player.on("ads-vast-content", (e) => {
      _postMessage("vast-content", e.content);
    });
    player.on("ads-vast-ad-detected", () => {
      _postMessage("vast-ad-detected", true);
    });
    player.on("ads-vast-ad-not-detected", () => {
      _postMessage("vast-ad-detected", false);
    });

    player.on("timeupdate", () => {
      _postMessage("timeupdate", player.currentTime());
    });
    player.on("loadedmetadata", () => {
      _postMessage("loadedmetadata", {
        duration: player.duration(),
        height: player.videoHeight(),
        width: player.videoWidth(),
        levels: JSON.stringify(player.qualityLevels()["levels_"]),
      });
      if (player.videoHeight() === 0 && player.videoWidth() === 0) {
        let metadata_interval = setInterval(() => {
          if (player.videoHeight() !== 0 && player.videoWidth() !== 0) {
            _postMessage("loadedmetadata", {
              duration: player.duration(),
              height: player.videoHeight(),
              width: player.videoWidth(),
              levels: JSON.stringify(player.qualityLevels()["levels_"]),
            });
            clearInterval(metadata_interval);
          }
        }, 500);
      }
    });
    player.on("durationchange", () => {
      _postMessage("durationchange", player.duration());
    });
    player.on("progress", () => {
      _postMessage("progress", {
        start: player.buffered().start(0),
        end: player.buffered().end(0),
      });
    });
    player.on("ratechange", () => {
      _postMessage("ratechange", player.playbackRate());
    });
    player.on("volumechange", () => {
      _postMessage("volumechange", player.volume() * 100);
    });
  }

  /**
   * Sets the listener that handles the control features of the player. This is the bridge to
   * our SDK so be mindful with changes. See _remoteEventListenerHandler for the actual handling.
   * @private
   */
  function _setRemoteEventListener() {
    window.addEventListener("message", _remoteEventListenerHandler);
  }

  /**
   * Actual handler for the remote events
   * @param event
   * @private
   */
  let _remoteEventListenerHandler = (event) => {
    if (p_debug) {
      console.debug("received message", event.data.event, event.data);
    }

    let action = event.data.event;

    let actions = {
      play: () => {
        player.play();
        if (event.data.position >= 0) {
          player.currentTime(event.data.position);
        }
      },
      pause: () => {
        player.pause();
      },

      controlsHide: () => {
        player.controlBar.hide();
      },
      controlsShow: () => {
        player.controlBar.show();
      },

      mute: () => {
        player.volume(0);
      },
      unMute: () => {
        player.volume(1);
      },
      setVolume: () => {
        player.volume(event.data.volume);
      },

      isFullscreen: () => {
        player.isFullscreen();
      },
      requestFullscreen: () => {
        player.requestFullscreen();
      },
      exitFullscreen: () => {
        player.exitFullscreen();
      },

      isInPip: () => {
        player.isInPictureInPicture();
      },
      requestPip: () => {
        player.requestPictureInPicture();
      },
      exitPip: () => {
        player.exitPictureInPicture();
      },

      controlBarButtonState: () => {
        let state = get_control_bar_button_state_callback();
        event.source.postMessage(
          {
            event: "controlBarState",
            data: state,
          },
          "*",
        );
      },
      controlBarButtonShow: () => {
        control_bar_button_show_callback(
          control_bar_options[event.data.button],
          event.data.show,
        );
      },

      setVASTUrl: () => {
        // Must be set right at the start?
        player.ima({
          adTagUrl: event.data.url,
        });
      },

      setVASTXml: () => {
        player.trigger({
          type: "ads-ad-load-vaststing",
          vastXml: event.data.value,
        });
      },

      setThemeColor: () => {
        set_theme_color_callback(event.data.key, event.data.value);
      },
      setPlayButtonImageUrl: () => {
        set_play_button_image_url_callback(event.data.value);
      },
      setPosterFrameUrl: () => {
        set_poster_frame_url_callback(event.data.value);
      },

      setAsset: () => {
        let file = event.data.file;
        let type = event.data.type;
        let service = event.data.service;
        let subtitles = event.data.subtitles;
        let chapters = event.data.chapters;
        let thumbnails = event.data.thumbnail;
        let poster_frame = event.data.poster_frame;
        let token = event.data.token;
        let extra_params = event.data.extra_params;
        set_asset_callback(
          file,
          type,
          service,
          subtitles,
          chapters,
          thumbnails,
          poster_frame,
          token,
          extra_params,
        );
      },

      requestChromeCast: () => {
        // Copied from AirPlay plugin
        // See: https://github.com/silvermine/videojs-chromecast/blob/67193fe16c98d908744e8a6e31fc7fd6b0604a4c/src/js/enableChromecast.js#L38
        if (player.chromecastSessionManager) {
          player.chromecastSessionManager.openCastMenu();
        }
      },

      requestAirPlay: () => {
        // Copied from AirPlay plugin:
        // See: https://github.com/silvermine/videojs-airplay/blob/3fdedc61cfaeb809bed4b8bbf9bee9489507a2fc/src/js/enableAirPlay.js#L48
        let mediaEl = player.el().querySelector("video, audio");

        if (mediaEl && mediaEl.webkitShowPlaybackTargetPicker) {
          mediaEl.webkitShowPlaybackTargetPicker();
        }
      },
    };

    if (actions[action]) {
      actions[action]();
    }
  };

  /**
   * Nice cleanup of the handlers.
   */
  // eslint-disable-next-line
  function dispose() {
    window.removeEventListener("message", _remoteEventListenerHandler);
  }

  // Bootstrap the event system
  _setEventMessengers();
  _setRemoteEventListener();

  // Signal player load
  if (window.parent) {
    window.parent.postMessage(
      {
        event: "_pp_initialize",
      },
      "*",
    );
    // We need to emit this because old Video.js did too. Otherwise for example VAST does not get loaded.
    window.parent.postMessage(
      {
        event: "loaded",
      },
      "*",
    );
  }
  return this;
}

export default PrivacyPlayerPostMessageAPI;
